import {
  Button,
  makeStyles,
  mergeClasses,
  Table,
  TableBody,
  TableCell,
  TableCellActions,
  TableCellLayout,
  TableRow,
} from "@fluentui/react-components";
import {
  CalendarAgendaRegular,
  Timeline20Regular,
} from "@fluentui/react-icons";
import { useSelector } from "react-redux";
import {
  selectActiveAgenda,
  selectCurrentUser,
  selectMeeting,
} from "../../reducers/meeting";
import { environment } from "../../environment";

const useStyles = makeStyles({
  active: {
    backgroundColor: "#00ff0014",
  },
});
export default function AgendaTab() {
  const meeting = useSelector(selectMeeting);
  const activeAgenda = useSelector(selectActiveAgenda);
  const currentUser = useSelector(selectCurrentUser);

  async function activateAgenda(id: string) {
    await fetch(environment.ADMIN_URL + "/api/msad/ra/activate-agenda", {
      method: "POST",
      body: JSON.stringify({
        id: id,
      }),
      headers: {
        "Content-type": "application/json",
      },
    });
  }

  async function startVote(id: string) {
    await fetch(environment.ADMIN_URL + "/api/msad/ra/start-vote", {
      method: "POST",
      body: JSON.stringify({
        "vote-id": id,
      }),
      headers: {
        "Content-type": "application/json",
      },
    });
  }

  async function stopVote(id: string) {
    await fetch(environment.ADMIN_URL + "/api/msad/ra/stop-vote", {
      method: "POST",
      body: JSON.stringify({
        "vote-id": id,
      }),
      headers: {
        "Content-type": "application/json",
      },
    });
  }
  const styles = useStyles();

  return (
    <div>
      <Table arial-label="Agenda List" size="small">
        <TableBody>
          {meeting.agenda.map((item: any) => (
            <TableRow
              className={mergeClasses(
                item.id === activeAgenda ? styles.active : ""
              )}
              key={item.id}
            >
              <TableCell>
                <TableCellLayout media={<CalendarAgendaRegular />}>
                  {item.title}
                </TableCellLayout>

                <TableCellActions>
                  {item.id !== activeAgenda && currentUser?.isOP && (
                    <Button
                      icon={<Timeline20Regular />}
                      onClick={() => {
                        activateAgenda(item.id);
                      }}
                    ></Button>
                  )}
                  &nbsp;
                  {item.id === activeAgenda &&
                    item.votes.length > 0 &&
                    currentUser?.isOP && (
                      <span>
                        {(item.votes[0].vote_status === "UPCOMING" ||
                          item.votes[0].vote_status === "FINISHED") && (
                          <Button
                            onClick={() => {
                              startVote(item.votes[0].id);
                            }}
                          >
                            Start vote
                          </Button>
                        )}

                        {item.votes[0].vote_status === "STARTED" && (
                          <Button
                            onClick={() => {
                              stopVote(item.votes[0].id);
                            }}
                          >
                            Stop vote
                          </Button>
                        )}
                      </span>
                    )}
                </TableCellActions>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
