import {
  makeStyles,
  mergeClasses,
  Spinner,
  ToggleButton,
} from "@fluentui/react-components";
import { useSelector } from "react-redux";
import {
  selectMeeting,
  selectTeamsWebcastStatus,
  TeamsWebcastStatus,
} from "../../reducers/meeting";
import { useCallback, useEffect, useState } from "react";
import { environment } from "../../environment";

import React from "react";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    rowGap: "1rem",
  },
  timer: {
    marginLeft: "auto",
  },
  timerLabel: {
    paddingRight: "0.5rem",
  },
  timerLabelLive: {
    color: "red",
    fontWeight: "bold",
  },
  timerWrapper: {
    textAlign: "right",
  },

  errorMsg: {
    color: "red",
    textAlign: "center",
  },
});

enum LocalWebcastStatus {
  Unset = "UNSET",
  None = "None",
  Stopping = "Stopping",
  Joining = "Joining",
  Starting = "Starting",
  Pausing = "Pausing",
  RESUMING = "Resuming",
}

const _error_failed_to_start_webcast =
  "Failed to start webcast. Please try again or contact support.";

const _error_failed_to_pause_webcast =
  "Failed to pause webcast. Please try again or contact support.";

const _error_failed_to_resume_webcast =
  "Failed to resume webcast. Please try again or contact support.";

export default function SettingsTab() {
  const meeting = useSelector(selectMeeting);
  const webcastState = useSelector(selectTeamsWebcastStatus);

  const [isTimerActive, setIsTimerActive] = useState(false);
  const [isTimerPaused, setIsTimerPaused] = useState(true);
  const [error, setError] = useState("");
  const [time, setTime] = useState(0);
  const [localStatus, setLocalStatus] = useState<LocalWebcastStatus>(
    LocalWebcastStatus.None
  ); // 0: stopped 1: started

  const styles = useStyles();

  // function stopStream() {
  //   meeting.requestStopLiveStreaming(() => {
  //   });
  // }

  //
  // Actions
  //

  async function openMeeting() {
    setLocalStatus(LocalWebcastStatus.Joining);

    setError("");

    fetch(environment.ADMIN_URL + "/api/msad/webcast/open", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        stream_id: meeting.stream_id,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          setError(_error_failed_to_start_webcast);
          setLocalStatus(LocalWebcastStatus.None);
        }
      })
      .catch(() => {
        setError(_error_failed_to_start_webcast);
        setLocalStatus(LocalWebcastStatus.None);
      });
  }

  const stopWebcast = useCallback(() => {
    setLocalStatus(LocalWebcastStatus.Stopping);

    fetch(environment.ADMIN_URL + "/api/msad/webcast/close", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        stream_id: meeting.stream_id,
      }),
    });
  }, [meeting.stream_id]);

  const startWebcast = useCallback(() => {
    fetch(environment.ADMIN_URL + "/msad/webcast/start", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        stream_id: meeting.stream_id,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          setError(_error_failed_to_start_webcast);
          stopWebcast();
        }
      })
      .catch(() => {
        setError(_error_failed_to_start_webcast);
        stopWebcast();
      });
  }, [meeting.stream_id, stopWebcast]);

  async function pauseWebcast() {
    setLocalStatus(LocalWebcastStatus.Pausing);

    await fetch(environment.ADMIN_URL + "/api/msad/webcast/pause", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        stream_id: meeting.stream_id,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          setError(_error_failed_to_pause_webcast);
          setLocalStatus(LocalWebcastStatus.None);
        }
      })
      .catch(() => {
        setError(_error_failed_to_pause_webcast);
        setLocalStatus(LocalWebcastStatus.None);
      });
  }

  async function resumeWebcast() {
    setLocalStatus(LocalWebcastStatus.RESUMING);

    await fetch(environment.ADMIN_URL + "/api/msad/webcast/resume", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        stream_id: meeting.stream_id,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          setError(_error_failed_to_resume_webcast);
          setLocalStatus(LocalWebcastStatus.None);
        }
      })
      .catch(() => {
        setError(_error_failed_to_resume_webcast);
        setLocalStatus(LocalWebcastStatus.None);
      });
  }

  function startTimer() {
    setTime(0);
    setIsTimerActive(true);
    setIsTimerPaused(false);
  }

  function pauseTimer() {
    setIsTimerPaused(true);
  }

  function resumeTimer() {
    setIsTimerPaused(false);
  }

  function stopTimer() {
    setIsTimerActive(false);
    setIsTimerPaused(true);
    // setTime(0);
  }

  useEffect(() => {
    setTime(Date.now() - webcastState.streaming_start_time);

    switch (webcastState.status) {
      case TeamsWebcastStatus.STOPPED:
        if (localStatus === LocalWebcastStatus.None) {
          stopTimer();
        }

        break;

      case TeamsWebcastStatus.PAUSED:
        if (localStatus === LocalWebcastStatus.None) {
          pauseTimer();
        }

        break;

      case TeamsWebcastStatus.JOINED:
        if (localStatus === LocalWebcastStatus.None) {
          setLocalStatus(LocalWebcastStatus.Joining);
        } else if (localStatus === LocalWebcastStatus.Joining) {
          setLocalStatus(LocalWebcastStatus.Starting);
          setTimeout(() => {
            startWebcast();
          }, 6000);
        }

        break;

      case TeamsWebcastStatus.STREAMING:
        if (!isTimerActive) {
          startTimer();
        }

        if (isTimerPaused) {
          resumeTimer();
        }

        if (localStatus === LocalWebcastStatus.Starting) {
          setLocalStatus(LocalWebcastStatus.None);
        }

        if (localStatus === LocalWebcastStatus.RESUMING) {
          setLocalStatus(LocalWebcastStatus.None);
        }

        break;
    }
  }, [webcastState, isTimerActive, isTimerPaused, localStatus, startWebcast]);

  React.useEffect(() => {
    let interval: any = null;

    if (isTimerActive && !isTimerPaused) {
      interval = setInterval(() => {
        setTime((time) => time + 10);
      }, 10);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isTimerActive, isTimerPaused]);

  return (
    <div className={styles.container}>
      {webcastState.status === TeamsWebcastStatus.UNSET && (
        <div>
          {" "}
          <Spinner appearance="primary" label="Loading, please wait..." />
        </div>
      )}

      {webcastState.status !== TeamsWebcastStatus.UNSET && (
        <div>
          <div className={styles.timerWrapper}>
            <span
              className={mergeClasses(
                styles.timerLabel,
                webcastState.status === TeamsWebcastStatus.STREAMING
                  ? styles.timerLabelLive
                  : ""
              )}
            >
              {webcastState.status === TeamsWebcastStatus.STREAMING
                ? "Live"
                : webcastState.status === TeamsWebcastStatus.PAUSED
                ? "Paused"
                : "Not Live"}
            </span>
            {webcastState.status === TeamsWebcastStatus.STREAMING && (
              <span className={styles.timer}>
                <span>
                  {("0" + Math.floor((time / 60000) % 60)).slice(-2)}:
                </span>
                <span>{("0" + Math.floor((time / 1000) % 60)).slice(-2)}</span>
              </span>
            )}
          </div>

          {(webcastState.status === TeamsWebcastStatus.STOPPED ||
            webcastState.status === TeamsWebcastStatus.JOINED) && (
            <ToggleButton
              disabled={
                localStatus === LocalWebcastStatus.Joining ||
                localStatus === LocalWebcastStatus.Starting
              }
              onClick={() => {
                openMeeting();
              }}
              size="large"
            >
              {localStatus === LocalWebcastStatus.Starting
                ? "Starting..."
                : "Start Webcast"}
            </ToggleButton>
          )}

          <div>
            {webcastState.status === TeamsWebcastStatus.STREAMING && (
              <ToggleButton
                disabled={
                  localStatus === LocalWebcastStatus.Pausing ||
                  localStatus === LocalWebcastStatus.Stopping
                }
                onClick={() => {
                  pauseWebcast();
                }}
                size="large"
              >
                {localStatus === LocalWebcastStatus.Pausing
                  ? "Pausing..."
                  : "Pause"}
              </ToggleButton>
            )}
            &nbsp;
            {webcastState.status === TeamsWebcastStatus.PAUSED && (
              <ToggleButton
                disabled={localStatus === LocalWebcastStatus.RESUMING}
                onClick={() => {
                  resumeWebcast();
                }}
                size="large"
              >
                {localStatus === LocalWebcastStatus.RESUMING
                  ? "Resuming..."
                  : "Resume"}
              </ToggleButton>
            )}
            &nbsp;
            {webcastState.status === TeamsWebcastStatus.STREAMING && (
              <ToggleButton
                disabled={
                  localStatus === LocalWebcastStatus.Pausing ||
                  localStatus === LocalWebcastStatus.Stopping
                }
                onClick={() => {
                  stopWebcast();
                }}
                size="large"
              >
                {localStatus === LocalWebcastStatus.Stopping
                  ? "Stopping..."
                  : "Stop"}
              </ToggleButton>
            )}
          </div>

          {error && <div className={styles.errorMsg}>{error}</div>}
        </div>
      )}
    </div>
  );
}
